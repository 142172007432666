import React from 'react'

export default function Company(props) {
    
    return (

            <div className={props.classContainer}>
            <a href={props.url}>
                <div className="margin">
                    <img
                    className={props.logoClass}
                    src={props.logoSrc}
                    alt={props.logoAlt}

                    />
                    <p>
                        {props.companyInfo}
                    </p>
                </div>
            </a>
                
            </div>

        
    )
}