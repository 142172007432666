import './App.css';
import Company from './Components/Company'
import MontgomeryProperties from './images/montgomery-properties-logo.png'
import MontgomeryBank from './images/montgomery-logo.png'
import Americare from './images/Americare.webp'
import MontgomeryFamily from './images/MontgomeryFamily.png'
import MIG from './images/MIG.png'




function App() {
  return (
    <div className="App">
      <div className="App-background">
        <div className="mobile-background">

        </div>
        <div className="Family-logo">
          <img className="Family-logo-image" src={MontgomeryFamily} alt="Montgomery Family Logo"/>
        </div>
        <div className="Companies-container">
          <Company 
            url={"https://montgomeryproperties.com/"}
            classContainer={"Companies Montgomery-properties"} 
            logoClass={"logo Montgomery-properties-logo"}
            logoSrc={MontgomeryProperties}
            logoAlt={"Montgomery Properties logo"}
            companyInfo={
                `Founded in 1994, Montgomery Properties is a family-owned real estate company that has developed, 
                owned, and managed multiple commercial properties throughout Missouri and surrounding states. 
                The family’s history in the real estate industry stretches back to the 1950’s when Joel Montgomery,
                Sr. became one of the largest residential developers in the Mid-South. Following in his footsteps,
                Joel Montgomery, Jr. decided to branch out into the world of commercial real estate with the 
                establishment of Montgomery Properties.`}
            />
            <Company
            url={"https://www.montgomerybank.com/"}
            classContainer={"Companies Montgomery-bank"} 
            logoClass={"logo MB-logo"}
            logoSrc={MontgomeryBank}
            logoAlt={"Montgomery Bank logo"}
            companyInfo={
              `With 12 branches in St. Louis and Southeast Missouri, Montgomery Bank, with over $1.3 billion in 
              assets, is one of the largest privately-held, family-owned banks in the state. The bank has been 
              family-owned since 1957 when its founder, Joel Montgomery, Sr., acquired controlling interest in 
              Planters Bank, later renamed First National Bank. The bank was renamed Montgomery Bank in 2004.`}
            />
            <Company 
            url={"https://www.americareusa.net/"}
            classContainer={"Companies Americare"} 
            logoClass={"logo Americare-logo"}
            logoSrc={Americare}
            logoAlt={"Americare logo"}
            companyInfo={
              `Since 1981 Americare has been a trusted name in senior living throughout Kansas, Missouri, Tennessee, 
              Illinois and Mississippi. Our living options include independent living, assisted living, memory care 
              assisted living, skilled nursing and rehabilitation. At the heart of every Americare community is a 
              philosophy that puts residents first and drives every decision we make. Our person-centered approach 
              makes a difference in the lives of our residents, their families and our employees.`}
            />
            <Company 
            url={"https://montgomeryig.net/"}
            classContainer={"Companies MIG"} 
            logoClass={"logo MIG-logo"}
            logoSrc={MIG}
            logoAlt={"MIG logo"}
            companyInfo={
              `Montgomery Intermediary Group (MIG) is a brokerage firm founded by Andrew Montgomery and is 
              focused on helping investors in senior living assets find great buying opportunities and helping 
              sellers of those assets locate buyers willing to pay the right price. Whatever your senior housing 
              needs or interests may be, all team members at MIG are capable and excited to help.`}
            />
        </div>
      </div>
    </div>
  );
}

export default App;
